#mapa {
    width: 2500px; /* Ajusta el ancho según tus necesidades */
    height: 1500px; /* Ajusta la altura según tus necesidades */
  /*    border: 1px solid #ccc;Bordes opcionales para resaltar el área del mapa */
    /*  position: relative;Establece la posición relativa para que los elementos internos se posicionen correctamente */
  }
  
  #mapa img {
    width: 100%; /* Hace que la imagen SVG ocupe el 100% del ancho del contenedor */
    height: 100%; /* Hace que la imagen SVG ocupe el 100% de la altura del contenedor */
    display: block; /* Elimina cualquier espacio adicional por debajo de la imagen */
  }

  .mi-path {
    fill: rgb(56, 56, 60); /* Estilo normal del relleno del path */
    transition: fill 0.3s ease; /* Agrega una transición suave */
  
    /* Otros estilos para el path si es necesario */
  }
  
  .adivinado {
    fill: rgb(56, 56, 60); /* Estilo normal del relleno del path */
    transition: fill 0.3s ease; /* Agrega una transición suave */
    fill-opacity:0;
  
    /* Otros estilos para el path si es necesario */
  }
  .mi-path:hover {
    fill: blue; /* Estilo del relleno cuando el mouse está sobre el path */
    cursor: 'pointer'
  }


  /* MiComponente.module.css */
.svgprovincia {
  margin-top: -110px;     /* Ajusta el margen superior según tus necesidades */
  margin-left: -275px;    /* Ajusta el margen izquierdo según tus necesidades */
   /* O relative, según tus necesidades */
     /*  top: 10px;        Ajusta la posición vertical según tus necesidades */
  /*  left: 20px;          Ajusta la posición horizontal según tus necesidades */
}
  /* MiComponente.module.css */
  .imagenprovincia {
    margin-top: -110px;     /* Ajusta el margen superior según tus necesidades */
    margin-left: -275px;    /* Ajusta el margen izquierdo según tus necesidades */
     /* O relative, según tus necesidades */
       /*  top: 10px;        Ajusta la posición vertical según tus necesidades */
    /*  left: 20px;          Ajusta la posición horizontal según tus necesidades */
  }