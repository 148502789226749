.ruleta-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh; /* Ocupa toda la altura de la ventana */
  position: relative;
}

.ruleta {
  width: 300px;
  height: 300px;
  border: 10px solid #333;
  border-radius: 50%;
  background: conic-gradient(
    green 0deg 72deg,        /* Fauna_y_Flora */
    blue 72deg 144deg,       /* Historia */
    yellow 144deg 216deg,    /* Comidas_Típicas */
    orange 216deg 288deg,    /* Mitos_y_Leyendas */
    red 288deg 360deg        /* Corona */
  );
  transition: transform 3s ease-out;
  position: relative;
  transform-origin: center;
}

.icono {
  position: absolute;
  width: 50px;
  height: 50px;
  top: 50%;
  left: 50%;
  transform-origin: center;
}

.marcador {
  position: absolute;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 20px solid red; /* Cambia para que apunte hacia abajo */
  top: -20px; /* Mantener el marcador arriba de la ruleta */
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}

.icono.fauna_y_flora {
  transform: translate(-50%, -50%) rotate(0deg) translateX(-110px);
}

.icono.historia {
  transform: translate(-50%, -50%) rotate(72deg) translateX(-110px);
}

.icono.comidas_tipicas {
  transform: translate(-50%, -50%) rotate(144deg) translateX(-110px);
}

.icono.mitos_y_leyendas {
  transform: translate(-50%, -50%) rotate(216deg) translateX(-110px);
}

.icono.corona {
  transform: translate(-50%, -50%) rotate(288deg) translateX(-110px);
}

.animacion-acierto {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: lightgreen;
  padding: 20px;
  border-radius: 10px;
  font-size: 24px;
  font-weight: bold;
  color: white;
  animation: destello 1s infinite alternate;
  z-index: 9999; /* Asegura que el destello esté por delante */
}

@keyframes destello {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.sonido-control {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1000;
}
.boton-respuesta {
  font-size: 1.2rem;
  padding: 10px 20px;
  min-width: 150px;
  max-width: 100%; /* Asegura que no excedan el tamaño del contenedor */
  flex: 1; /* Esto hará que todos los botones crezcan uniformemente */
  word-wrap: break-word; /* Para dividir palabras largas */
  text-align: center; /* Centra el texto dentro de los botones */
}

