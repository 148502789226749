body {
  margin: 0;
  padding: 0;
  background-size: cover;
  font-family: 'Montserrat', sans-serif;
}

/* Imagen de fondo para pantallas más grandes (PC) */
body {
  background-image: url('../../Assets/votacion/fondoesc.jpg'); /* Cambia esta ruta por tu imagen */
}
h1, h2, h3, h4, h5, h6, p, a, button, input, textarea {
  font-family: 'Montserrat', sans-serif;
}
/* Imagen de fondo para pantallas móviles */
@media (max-width: 768px) {
  body {
    background-image: url('../../Assets/votacion/fondocel2.jpg'); /* Cambia esta ruta por tu imagen */
  }
  
}
@media (max-width: 768px) {
  .category-group {
    margin: 10px 0;
  }
  .subcategory-group {
    margin-left: 20px;
  }
}
.fixed-text {
  position: fixed;
  top: 50%; /* Centrado verticalmente */
  /* right: -35%; Pegado al lado derecho */
  right: -8%;
  transform: translateY(-50%) rotate(-90deg); /* Centrado exacto y rotado */
  background-color: rgba(0, 0, 0, 0.7); /* Fondo semitransparente */
  color: white;
  padding: 10px 20px; /* Espaciado interno */
  font-size: 16px; /* Tamaño de texto */
  font-family: 'Montserrat', sans-serif; /* Fuente */
  border-radius: 5px 0 0 5px; /* Bordes redondeados en la parte izquierda */
  z-index: 1000; /* Asegura que esté encima del contenido */
  text-align: center; /* Alinea el texto */
}
@media (max-width: 768px) {
  .fixed-text {
    right: -35%; /* Posición para pantallas pequeñas */
  }
}

.voting-page {
  background-image: url('../../Assets/votacion/tit1.jpeg'); /* Cambia esta ruta por tu imagen */
  color: white;
  text-align: center;
  padding: 20px;
   
    background-Position: 'center top';
  opacity: 0.95; /* Establecer opacidad */
   min-Height: '70vh';
}
/* Fondo para categoría */
/* Fondo para categoría */
.background-category {
  background-image: url('../../Assets/votacion/tit1.jpeg'); /* Ruta para el fondo de categoría */
  width: 100%; /* Asegura que cubra todo el ancho del viewport */
  height: 250px; /* Ajusta la altura deseada */
  background-position: center center; /* Centra horizontal y verticalmente */
  background-repeat: no-repeat; /* Evita que la imagen se repita */
  background-size: cover; /* Escala la imagen para cubrir todo el contenedor */
}

/* Fondo para subcategoría */
.background-subcategory {
  background-image: url('../../Assets/votacion/tit2.jpeg'); /* Ruta para el fondo de subcategoría */
  width: 100%; /* Asegura que cubra todo el ancho del viewport */
  height: 200px; /* Ajusta la altura deseada */
  background-position: center center; /* Centra horizontal y verticalmente */
  background-repeat: no-repeat; /* Evita que la imagen se repita */
  background-size: cover; /* Escala la imagen para cubrir todo el contenedor */
}
.background-voting {
  background-image: url('../../Assets/votacion/fondoescr.jpg'); /* Ruta para fondo de votación */
  background-size: cover;
  background-position: center;
}

.voting-title {
  font-size: 2em;
  margin-bottom: 20px;
}

.category-select button, .subcategory-select button {
  margin: 10px;
  padding: 10px 20px;
  background-color: #1E90FF;
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

.category-select button:hover, .subcategory-select button:hover {
  background-color: #187bcd;
}

.voting-category {
  margin: 20px 0;
}
.option-voted {
  border: 2px solid #ffa500; /* Borde naranja para indicar votado */
}

.voted-message {
  color: #ffa500;
  font-weight: bold;
  margin-top: 10px;
  text-align: center;
}
.category-title {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.vote-options-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
}

.vote-option {
  border: 2px solid white;
  border-radius: 10px;
  width: 150px;
  padding: 10px;
  transition: transform 0.3s;
  cursor: pointer;
  position: relative;
  opacity: 1; /* Visible inicialmente */
}

.vote-option:hover {
  transform: scale(1.1);
}

.option-image-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 100%;
  height: 160px;
}

.option-image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Mantiene la relación de aspecto de la imagen */
  object-position: top; /* Recorta desde la parte superior de la imagen */
}

/* Responsividad para móviles */
@media (max-width: 600px) {
  .vote-option {
    width: 100%;
  }

  /* Cambiar la altura de las imágenes para móviles (hacerlas más verticales) */
  .option-image-wrapper {
    height: 350px; /* Aumentar la altura de las imágenes para móvil */
  }

  .option-image {
    height: 100%; /* Mantener la imagen ajustada al nuevo tamaño */
    object-fit: cover;
  }
}

.option-info h3 {
  margin: 10px 0 5px;
}

.option-info p {
  font-size: 0.9em;
}

/* Estilo para ocultar el botón de votar hasta que se vote */
.option-vote {
  display: none; /* Oculto inicialmente */
}

.option-voted .option-vote {
  display: block; /* Se muestra solo cuando se vota */
}

.vote-percentage {
  margin-top: 10px;
}

.option-voted .vote-percentage {
  display: block; /* Muestra el porcentaje cuando se vote */
}

.vote-option .progress-bar {
  width: 100%;
  height: 10px;
  background-color: #ddd;
  border-radius: 5px;
  overflow: hidden;
}

.vote-option .progress-bar .progress-fill {
  height: 100%;
  background-color: #1E90FF;
  width: 0%; /* Esto lo actualizarás en React con el porcentaje de votos */
  border-radius: 5px;
}

.next-category-btn {
  background-color: #1E90FF;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  margin-top: 20px;
}

.next-category-btn:hover {
  background-color: #187bcd;
}

/* Responsividad para móvil */
@media (max-width: 600px) {
  .vote-option {
    width: 100%;
  }
}
/* Estilos generales para la página de votación */
.vote-option {
  position: relative;
  border: 2px solid white;
  border-radius: 10px;
  width: 150px;
  padding: 10px;
  cursor: pointer;
  transition: transform 0.3s;
}

.vote-option:hover {
  transform: scale(1.1);
}

/* Imagen de la opción */
.option-image-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 100%;
  height: 160px;
}

.option-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
}

/* Estilos para el modal */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Fondo oscuro */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Asegúrate de que esté por encima del contenido */
}

.modal-content {
  position: relative;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
}

/* Imagen grande dentro del modal */
.modal-image {
  max-width: 100%;
  max-height: 80vh;
  object-fit: contain;
}

/* Botón para cerrar el modal */
.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: red;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
}
