/* src/App.css */
.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  font-family: Arial, sans-serif;
}

.board {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
}

.cell {
  width: 40px;
  height: 40px;
  border: 1px solid #000;
}

.active {
  background-color: #007bff;
}

.obstacle {
  background-color: #ff0000;
}

.goal {
  background-color: #00ff00;
}

.control-panel {
  margin-top: 20px;
}

.control-panel textarea {
  padding: 10px;
  font-size: 16px;
  margin-right: 10px;
  resize: none;
}

.control-panel button {
  padding: 10px;
  font-size: 16px;
}

.message {
  margin-top: 20px;
  font-size: 18px;
  color: green;
}

.level-selector {
  margin-bottom: 20px;
}

.level-selector button {
  margin: 5px;
  padding: 10px;
  font-size: 16px;
}
